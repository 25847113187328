import React from "react";
import "./styles.css";

import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";

import logo from "../../assets/logo_topo.png";

const Menu = () => {
  return (
    <nav id="menu">
      <section className="container">
        <figure className="left">
          <img src={logo} alt="Logo" />
        </figure>

        <article className="right">
          <CtaTel />
          <CtaWpp />
        </article>
      </section>
    </nav>
  );
};

export default Menu;
