import "./styles.css";

import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import AVTR1 from "../../assets/about2_1.png";
import AVTR2 from "../../assets/about2_2.png";
import AVTR3 from "../../assets/about2_3.png";
import AVTR4 from "../../assets/about2_4.png";
import AVTR5 from "../../assets/about2_5.png";
import AVTR6 from "../../assets/about2_6.png";
import AVTR7 from "../../assets/about2_7.png";
import AVTR8 from "../../assets/about2_8.png";
import AVTR9 from "../../assets/about2_9.png";

SwiperCore.use([Navigation, Pagination, Autoplay]);

const data = [
  {
    id: 1,
    image: AVTR1,
  },
  {
    id: 2,
    image: AVTR2,
  },
  {
    id: 3,
    image: AVTR3,
  },
  {
    id: 4,
    image: AVTR4,
  },
  {
    id: 5,
    image: AVTR5,
  },
  {
    id: 6,
    image: AVTR6,
  },
  {
    id: 7,
    image: AVTR7,
  },
  {
    id: 8,
    image: AVTR8,
  },
  {
    id: 9,
    image: AVTR9,
  },
];

const About = () => {
  return (
    <main id="about">
      <section className="about1">
        <div className="container">
          <h2 className="title">
            Contrate nossos planos de saúde e odontológicos:
          </h2>

          <div className="gallery">
            <div className="item">
              <figure>
                <img src={about1_1} alt="Individuais e Familiares" />
              </figure>

              <h4 className="title">Individuais e Familiares</h4>

              <p className="text">
                O plano ideal para cuidar <br />
                de você e sua família.
              </p>
            </div>

            <div className="item">
              <figure>
                <img src={about1_2} alt="Pequenas e Médias Empresas" />
              </figure>

              <h4 className="title">Pequenas e Médias Empresas</h4>

              <p className="text">
                <span>(2 a 99 vidas)</span> <br />
                Inovação e cuidado para pequenas e médias empresas.
              </p>
            </div>

            <div className="item">
              <figure>
                <img src={about1_3} alt="Empresas de Grande Porte" />
              </figure>

              <h4 className="title">Empresas de Grande Porte</h4>

              <p className="text">
                <span>(de 100 a 2.999 vidas)</span> <br />
                Soluções inteligentes para saúde da sua empresa.
              </p>
            </div>

            <div className="item">
              <figure>
                <img src={about1_4} alt="Empresas de Grande Porte" />
              </figure>

              <h4 className="title">Empresas de Grande Porte</h4>

              <p className="text">
                <span>(a partir de 3.000 vidas)</span> <br />
                (a partir de 3.000 vidas) Soluções inteligentes para saúde da
                sua empresa.
              </p>
            </div>
          </div>

          <div className="ctas">
            <p className="text">
              Entre em contato e <b>faça uma cotação:</b>
            </p>

            <div className="cta-btns">
              <CtaTel />
              <CtaWpp />
            </div>
          </div>
        </div>
      </section>

      <section className="about2">
        <div className="container">
          <h2 className="title">
            Hapvida + NotreDame Intermédica juntas oferecem:
          </h2>

          <Swiper
            className="carousel"
            spaceBetween={20}
            slidesPerView={
              /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
              )
                ? 3
                : 7
            }
            navigation
            loop
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {data.map(({ id, image }) => {
              return (
                <SwiperSlide key={id} className="img">
                  <figure>
                    <img src={image} alt={`Exemplo ${id} de Foto`} />
                  </figure>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="ctas">
            <p className="text">
              Entre em contato e <b>faça uma cotação:</b>
            </p>

            <div className="cta-btns">
              <CtaTel />
              <CtaWpp />
            </div>
          </div>
        </div>
      </section>

      <section className="about3">
        <div className="container">
          <h2 className="title">
            Faça uma cotação <br />
            <span>
              e encontre o melhor plano com preço acessível para você ou para os
              colaboradores da sua empresa!
            </span>
          </h2>

          <div className="ctas">
            <p className="text">Solicite uma proposta:</p>

            <div className="cta-btns">
              <CtaTel />
              <CtaWpp />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default About;
