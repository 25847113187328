import "./styles.css";

import CtaTel from "../../components/CTATel";
import CtaWpp from "../../components/CTAWpp";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <h1 className="title">
            Faça já seu <br />
            <span>plano de saúde</span>
          </h1>

          <p className="text">
            Aproveite os melhores valores dos planos individuais para manter sua{" "}
            <span>saúde</span> em dia!
          </p>

          <div className="ctas">
            <p className="text">
              Entre em contato e <b>faça uma cotação:</b>
            </p>

            <div className="cta-btns">
              <CtaTel />
              <CtaWpp />
            </div>
          </div>
        </article>
      </section>

      <section id="mobile">
        <div className="container">
          <div className="ctas">
            <p className="text">
              Entre em contato e <b>faça uma cotação:</b>
            </p>

            <div className="cta-btns">
              <CtaTel />
              <CtaWpp />
            </div>
          </div>
        </div>
      </section>

      <section className="header2">
        <article className="container">
          <h2 className="title">ESCOLHA SEU PLANO INDIVIDUAL</h2>

          <div className="gallery">
            <div className="item">
              <h3 className="title">Ambulatorial + Odonto</h3>
              <p className="text">
                <b>00 a 18 anos |</b> Com coparticipação
              </p>

              <div className="value">
                <p className="about-text">A partir de:</p>

                <p className="value-text">R$ 134,07</p>
              </div>
            </div>

            <div className="item">
              <h3 className="title">Enfermaria + Odonto</h3>
              <p className="text">
                <b>00 a 18 anos |</b> Com coparticipação
              </p>

              <div className="value">
                <p className="about-text">A partir de:</p>

                <p className="value-text">R$ 167,54</p>
              </div>
            </div>

            <div className="item">
              <h3 className="title">Apartamento + Odonto</h3>
              <p className="text">
                <b>00 a 18 anos |</b> Com coparticipação
              </p>

              <div className="value">
                <p className="about-text">A partir de:</p>

                <p className="value-text">R$ 241,48</p>
              </div>
            </div>
          </div>

          <div className="ctas">
            <p className="text">
              Entre em contato e <b>faça uma cotação:</b>
            </p>

            <div className="cta-btns">
              <CtaTel />
              <CtaWpp />
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
